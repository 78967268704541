$(document).ready(function () {
	//global
	textfieldFocusAnimation();
	toggleCart();
	trackVideo();

	//Header Mapping
	headerScrollYListener();
	headerZoneMapping();
	headerSearchMapping();
	headerLanguageMapping();
	headerZoneNavMapping();

	//Mobile Header
	toggleMobileHeader();
	toggleAccount();

	//Desktop Header
	toggleSearchbox();
	toggleLanguage();

	//Banner
	homeBannerInit();

	//ProductList
	productFilterMapping();
	toggleProductFilterMobile();
	toggleProductFilterGroup();
	activeFirstProductFilterGroupWhenPageLoaded();

	//Product Detail
	setProductDetailSliderHeight();
	productDetailSliderInit();

	//Checkout
	touchspinInit();
	customRadioInit();
	customCheckboxInit();
});


var headerZoneMapping = function () {
	try {
		return new MappingListener({
			selector: '.canhcam-header-1 .header-zone-list',
			mobileWrapper: '.mobile-header',
			mobileMethod: 'appendTo',
			desktopWrapper: '.canhcam-header-1 .logo',
			desktopMethod: 'insertAfter',
			breakpoint: 992
		}).watch();
	} catch (error) {}
}

var headerSearchMapping = function () {
	try {
		return new MappingListener({
			selector: '.canhcam-header-1 .search',
			mobileWrapper: '.mobile-header .mobile-sidenav',
			mobileMethod: 'prependTo',
			desktopWrapper: '.desktop-header .hamburger',
			desktopMethod: 'insertBefore',
			breakpoint: 992
		}).watch();
	} catch (error) {}
}

var headerLanguageMapping = function () {
	try {
		return new MappingListener({
			selector: '.language',
			mobileWrapper: '.mobile-header .mobile-sidenav',
			mobileMethod: 'appendTo',
			desktopWrapper: '.account',
			desktopMethod: 'insertAfter',
			breakpoint: 992
		}).watch();
	} catch (error) {}
}

var headerZoneNavMapping = function () {
	try {
		return new MappingListener({
			selector: '.zone-nav',
			mobileWrapper: '.mobile-header',
			mobileMethod: 'appendTo',
			desktopWrapper: '.desktop-header',
			desktopMethod: 'appendTo',
			breakpoint: 992
		}).watch();
	} catch (error) {}
}

var toggleMobileHeader = function () {
	$('body').on('click', '.canhcam-header-1 .hamburger', function () {
		$(this).toggleClass('active');
		$('.mobile-header').toggleClass('active');
		$('.header-container').toggleClass('active');
	});
}

var textfieldFocusAnimation = function () {
	$('body').on('focusin', 'input:text, textarea', function () {
		$(this).parent().addClass('active');
	});
	$('body').on('focusout', 'input:text, textarea', function () {
		$(this).parent().removeClass('active');
	});
}

var headerScrollYListener = function () {
	if ($('html, body').scrollTop() > 60) {
		$('.header-container').removeClass('attop');
	} else {
		if (!$('.header-container').hasClass('attop')) {
			$('.header-container').addClass('attop')
		}
	}
}

var toggleSearchbox = function () {
	$('body').on('click', '.btn-search', function () {
		$('.language').removeClass('active');
		$('.search').toggleClass('active');
	});
}

var toggleLanguage = function () {
	$('body').on('click', '.current-language', function () {
		$('.search').removeClass('active');
		$('.language').toggleClass('active');
	});
}

var toggleAccount = function() {
	if($(window).outerWidth() < 992) {
		$('body').on('click', '.canhcam-header-1 .mdi.mdi-account-circle', function() {
			$(this).next().toggleClass('active');
		});
	}
}

var homeBannerInit = function () {
	return new Swiper('.banner-trangchu .swiper-container', {
		slidesPerView: 1,
		spaceBetween: 0,
		autoplay: true,
		speed: 4000,
		effect: 'fade',
		navigation: {
			nextEl: '.button-next',
			prevEl: '.button-prev',
		}
	});
}

var productFilterMapping = function () {
	try {
		return new MappingListener({
			selector: '.product-filter',
			mobileWrapper: '.mobile-filter',
			mobileMethod: 'appendTo',
			desktopWrapper: '.desktop-filter',
			desktopMethod: 'appendTo',
			breakpoint: 992
		}).watch();
	} catch (error) {}
}

var hideProductFilterButtonWhenScrollIntoFooter = function () {
	if ($('.btn-filter').length) {
		if (($('html, body').scrollTop()) >= ($('footer').offset().top + $('footer').outerHeight() - $(window).outerHeight())) {
			$('.btn-filter').addClass('hidden');
		} else {
			if ($('.btn-filter').hasClass('hidden')) {
				$('.btn-filter').removeClass('hidden');
			}
		}
	}
}

var toggleProductFilterMobile = function () {
	$('body').on('click', '.mobile-filter-wrapper .btn-filter', function () {
		$(this).next().toggleClass('active');
	});
	$('body').on('click', '.mobile-filter-wrapper .btn-close', function () {
		$(this).parent().removeClass('active');
	});
}

var toggleProductFilterGroup = function () {
	$('body').on('click', '.product-filter .group span', function () {
		$(this).next().slideToggle(250);
		$(this).parent().toggleClass('active');
	});
}

var activeFirstProductFilterGroupWhenPageLoaded = function () {
	$('.product-filter .group span').first().trigger('click');
}

var productDetailSliderInit = function () {
	try {
		var productThumbs = new Swiper('.product-thumbs .swiper-container', {
			direction: 'vertical',
			slidesPerView: 5,
			spaceBetween: 10,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			centeredSlides: true,
			slideToClickedSlide: true
		});
		var productImages = new Swiper('.product-images .swiper-container', {
			direction: 'vertical',
			slidesPerView: 1,
			spaceBetween: 0,
		});

		productImages.controller.control = productThumbs;
		productThumbs.controller.control = productImages;

		var productRelated = new Swiper('.product-related .swiper-container', {
			slidesPerView: 4,
			spaceBetween: 30,
			loop: true,
			navigation: {
				nextEl: '.button-next',
				prevEl: '.button-prev',
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 10
				},
				992: {
					slidesPerView: 2
				}
			}
		});
	} catch (error) {

	}
}

var setProductDetailSliderHeight = function () {
	var currentWidth = $('.service-detail .product-images').outerWidth();
	$('.service-detail .product-images .swiper-container').css('height', currentWidth);
	$('.service-detail .product-images .swiper-slide').css('height', currentWidth);
}

var toggleCart = function () {
	$('body').on('click', '.btn-cart', function () {
		if (!$(this).next().hasClass('active')) {
			$(this).next().addClass('active');
		}
	});
	$('body').on('click', '.cart .btn-close', function () {
		if ($(this).parent().parent().hasClass('active')) {
			$(this).parent().parent().removeClass('active');
		}
	});
}

var touchspinInit = function () {
	$('body').on('click', '.touchspin .minus', function () {
		var inputSelector = $(this).next();
		var currentValue = +inputSelector.val();
		inputSelector.val(--currentValue);
		inputSelector.trigger('change');
	});
	$('body').on('click', '.touchspin .plus', function () {
		var inputSelector = $(this).prev();
		var currentValue = +inputSelector.val();
		inputSelector.val(++currentValue);
		inputSelector.trigger('change');
	});
}

var customRadioInit = function () {
	$('body').on('click', '.custom-radio', function () {
		var groupName = $(this).children('input').attr('name');
		$("input:radio[name='" + groupName + "']").removeAttr('checked');
		$(this).children('input').attr('checked', '');
		$(".custom-radio[data-name='" + groupName + "']").removeClass('active').next().removeClass('active');
		$(this).addClass('active').next().addClass('active');
		$(this).children('input').trigger('change');
	});
}

var customCheckboxInit = function () {
	$('body').on('click', '.custom-checkbox', function (e) {
		e.preventDefault();
		var currentCheckbox = $(this).children('input');
		var isChecked = currentCheckbox.is(':checked');
		if (isChecked) {
			currentCheckbox.removeAttr('checked');
			$(this).removeClass('active');
		} else {
			currentCheckbox.attr('checked', '');
			$(this).addClass('active');
		}
		currentCheckbox.trigger('change');
	});
}

var trackVideo = function () {
	if ($('.intro-page').length) {
		if ($(window).outerWidth() >= 992) {
			setTimeout(function () {
				window.location.href = "/home"
			}, 13000)
		} else {
			window.location.href = "/home"
		}
	}
}

window.onscroll = function () {
	headerScrollYListener();
	hideProductFilterButtonWhenScrollIntoFooter();
}